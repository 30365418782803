import React from 'react'
// import {NavLink} from 'react-router-dom'
// import { useTranslation } from 'react-i18next'

const Recordings = () => {
    // const [t] = useTranslation("global") // Here we pass the translation file
    const recordings = [
      {
        id:1,
        date: '12.08.2023', //dd.mm.yyyy
        title: 'Richard Strauss, Konzert für Oboe und kleines Orchester D-Dur',
        link: 'https://www.youtube.com/embed/mq6N0cKURe4?si=7IYvhjpHCqWpVSpd'
      },
      {
        id:2,
        date: '12.08.2023', //dd.mm.yyyy
        title: 'Camille Saint-Saëns, Sonate op. 166 pour Hautbois et Piano',
        link: 'https://www.youtube.com/embed/ztWerCe5Vr8?si=TWnLXn6bjoeM2tvA'
      }
    ] 
  return (
    <div className='main-bg flex flex-col gap-1 md:pt-0 px-5 2xl:px-64'>
      <section className='flex flex-row justify-center flex-wrap py-10 gap-20 text-col-lgh'>
      {recordings.map((rec) => (
        <div key={rec.id} className='xl:w-[45%] flex flex-col gap-5'>
          
          <iframe src={rec.link} title={rec.title} allowFullScreen className='w-full aspect-video'></iframe>
          <p className='tracking-widest font-hind-kochi-light text-base'>REC/{rec.date}</p>
          <div className='w-[10rem] md:w-[30rem] h-0.5 bg-col-hg'></div>
          <p className='tracking-widest font-hind-kochi-light text-2xl'>{rec.title}</p>
          {/* <NavLink to='/Bio' className='bg-col-hg w-fit px-6 py-3 font-hind-kochi-bold text-lg hover:bg-col-lgh hover:text-col-hg'>{t("buttons.show-full")}</NavLink> */}

        </div>
      ))} 
      </section>
    </div>
  )
}

export default Recordings