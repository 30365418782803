import React from 'react'
import {NavLink} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Bio = () => {
  const [t] = useTranslation("global") // Here we pass the translation file

  return (
    <div className='main-bg flex flex-col gap-1 pt-10 md:pt-0'>
      <section className='bg-none lg:bg-bio-image pos-pos-bio flex flex-row justify-stretch gap-5 text-col-lgh h-screen'>
        
        <div className='w-full block lg:hidden h-full px-8 md:px-14 gap-y-5 bg-bio-image pos-pos bg-cover bg-center lg:bg-none absolute opacity-30'></div>
        <div className='w-full h-[80%] lg:h-full flex flex-col justify-center px-10 lg:pl-[20%] lg:pr-16 gap-y-10 z-10'>
            <div className='w-full flex flex-col justify-center items-end gap-y-5'>
                <p className='py-10 px-8 bg-col-hg text-2xl leading-normal lg:pl-19 font-hind-kochi-light'>
                    {t("bio.header")} {/* {translate ("pass the key")} */}
                </p>
            </div>
            <NavLink to='/Photos' className='w-fit px-6 py-3 border-2 border-col-lgh font-hind-kochi-bold text-lg hover:border-col-hg hover:text-col-hg'>{t("buttons.other-photos")}</NavLink>
        </div>
        <div className='hidden lg:block w-full'></div>
      </section>
      <section className='px-10 lg:px-[20%] py-20 text-col-lgh'>
        <div className='flex flex-col gap-24'>
          <div className='flex flex-col gap-6'>
            <p className='font-bordeaux-regular text-2xl md:text-3xl tracking-norm'>{t("bio.pt1.title")}</p>
            <p className='font-hind-kochi-regular text-lg md:text-lg tracking-wide'>{t("bio.pt1.content")}</p>
          </div>
          <div className='flex flex-col gap-6'>
            <p className='font-bordeaux-regular text-2xl md:text-3xl tracking-norm'>{t("bio.pt2.title")}</p>
            <p className='font-hind-kochi-regular text-lg md:text-lg tracking-wide'>{t("bio.pt2.content")}</p>
          </div>
          <div className='flex flex-col gap-6'>
            <p className='font-bordeaux-regular text-2xl md:text-3xl tracking-norm'>{t("bio.pt3.title")}</p>
            <p className='font-hind-kochi-regular text-lg md:text-lg tracking-wide'>{t("bio.pt3.content")}</p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Bio