import './App.css';
import Home from "./components/Home"
import { BrowserRouter as Router,Routes, Route, Navigate } from 'react-router-dom';
import Nav from './components/Nav';
import Footer from './components/Footer';
import Bio from './components/Bio';
import Recordings from './components/Recordings';
import Photos from './components/Photos';


function App() {

  return (
    <Router>
      <Nav/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/Bio' element={<Bio/>}/>
        <Route path='/Recordings' element={<Recordings/>}/>
        <Route path='/Photos' element={<Photos/>}/>
        <Route path='*' element={<Navigate to="/"/>}/>
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
