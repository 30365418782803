import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/zoom';

import { Navigation} from 'swiper/modules';

const Photos = () => {

    var images = [
        {
            id:1,
            image: 'bg-home-image'
        },
        {
            id:2,
            image: 'bg-wai-image-2'
        },
        {
            id:3,
            image: 'bg-wai-image-3'
        },
        {
            id:4,
            image: 'bg-home-image'
        },
        {
            id:5,
            image: 'bg-wai-image-2'
        },
        {
            id:6,
            image: 'bg-yellow-500'
        },
        {
            id:7,
            image: 'bg-red-500'
        },
        {
            id:8,
            image: 'bg-orange-500'
        },
        {
            id:9,
            image: 'bg-green-500'
        },
        {
            id:10,
            image: 'bg-blue-500'
        },
        // {
        //     id:11,
        //     image: 'bg-yellow-500'
        // },
        // {
        //     id:12,
        //     image: 'bg-red-500'
        // },
        

    ];    

    var reps = images.length/5
    var start = 0 //start slice
    var end = 5 //end slice
    var photos = [] //final array
    for (let i = 0; i<reps; i++, start=start+5,end=end+5){
        var slice = images.slice(start, end);
        photos.push(slice)
    }
  return (
    <div className='main-bg h-screen'>
        <div className='hidden lg:block h-screen'>
            <Swiper
                slidesPerView={1}
                spaceBetween={0}
                zoom={true}
                pagination={{
                clickable: true,
                }}
                modules={[Navigation]}
                className="mySwiper w-full h-full"
            >
                {photos.map((slide)=>{
                    return(
                        <SwiperSlide className='swiper-zoom-container w-full'>
                            {slide.map((image) => (
                                <div key={image.id} className={`md:w-72 hover:w-full pos-pos-photos h-full duration-500 bg-center bg-cover ` + image.image}></div>
                            ))}
                        </SwiperSlide>
                    )
                })}                
            </Swiper>
            <div className="fill-col-lgh absolute top-1/2 -translate-y-1/2 right-10">
                <svg  width="46" height="46" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M3.12 12.48a.6.6 0 0 1 .6-.6h14.152l-3.777-3.775a.6.6 0 1 1 .85-.85l4.8 4.8a.6.6 0 0 1 0 .85l-4.8 4.8a.6.6 0 1 1-.85-.85l3.777-3.775H3.72a.6.6 0 0 1-.6-.6Z" clip-rule="evenodd"></path>
                </svg>
            </div>
            
        </div>
        <div className='lg:hidden h-screen'>
            <Swiper
                slidesPerView={1}
                spaceBetween={0}
                zoom={true}
                pagination={{
                clickable: true,
                }}
                modules={[Navigation]}
                className="mySwiper w-full h-full"
                >
                {images.map((slide)=>{
                    return(
                        <SwiperSlide className='w-full lg:bg-lime-500'>
                                <div key={slide.id} className={`w-full h-full duration-500 bg-center bg-cover `+ slide.image}></div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            <div className="fill-col-lgh absolute top-36 -translate-y-1/2 right-5 z-10">
                <svg  width="46" height="46" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M3.12 12.48a.6.6 0 0 1 .6-.6h14.152l-3.777-3.775a.6.6 0 1 1 .85-.85l4.8 4.8a.6.6 0 0 1 0 .85l-4.8 4.8a.6.6 0 1 1-.85-.85l3.777-3.775H3.72a.6.6 0 0 1-.6-.6Z" clip-rule="evenodd"></path>
                </svg>
            </div>
        </div>
    </div>
  )
}

export default Photos