import { useState } from 'react'
import { Dialog, Popover} from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'

import {NavLink} from 'react-router-dom'

// IMPORT TRANSLATIONS
import { useTranslation } from 'react-i18next'

export default function Nav() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [t,i18n] = useTranslation("global")

  const activeLink = 'text-col-hg border-b-2 border-col-hg';
  const unactiveLink = 'text-col-lgh hover:text-col-hg';

  const phoneActiveLink = '-mx-3 block px-3 py-2 text-col-hg border-b-2 border-col-hg'
  const phoneUnactiveLink = '-mx-3 block px-3 py-2 text-col-lgh hover:text-col-hg'

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
   }
   
  return (
    <header className="main-bg">
      <nav className="mx-3 flex max-w-full items-center justify-between p-6 lg:px-8 lg:justify-end" aria-label="Global">
        <div className="flex lg:flex-1">
          <NavLink to='/' className="-m-1.5 p-1.5">
            <h1 className='text-2xl text-col-lgh font-bordeaux-regular tracking-wild'>VOJTĚCH POSPÍŠIL</h1>
          </NavLink>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-col-lgh"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12 font-bordeaux-regular tracking-widest text-lg leading-6 text-col-lgh">
          <NavLink to='/' className={({isActive}) => isActive ? activeLink : unactiveLink}>
            {t("nav.first-link")}
          </NavLink>
          <NavLink to='/Bio' className={({isActive}) => isActive ? activeLink : unactiveLink}>
          {t("nav.second-link")}
          </NavLink>
          <NavLink to='/Recordings' className={({isActive}) => isActive ? activeLink : unactiveLink}>
          {t("nav.third-link")}
          </NavLink>
          <NavLink to='Photos' className={({isActive}) => isActive ? activeLink : unactiveLink}>
          {t("nav.fourth-link")}
          </NavLink>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end tracking-normal text-col-lgh text-base leading-6">
            <button onClick={() => handleChangeLanguage("en")}>
                {/* Log in <span aria-hidden="true">&rarr;</span> */}
                EN
            </button>
            /
            <button onClick={() => handleChangeLanguage("de")}>
                {/* Log in <span aria-hidden="true">&rarr;</span> */}
                DE
            </button>
            </div>
        </Popover.Group>
        
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-stone-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              {/* <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt=""
              /> */}
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-col-lgh"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-stone-400/10">
              <div className="space-y-2 py-6 font-bordeaux-regular tracking-widest text-base leading-6 text-col-lgh">
                <NavLink to='/' className={({isActive}) => isActive ? phoneActiveLink : phoneUnactiveLink} onClick={() => setMobileMenuOpen(false)}>
                    {t("nav.first-link")}
                </NavLink>
                <NavLink to='/Bio' className={({isActive}) => isActive ? phoneActiveLink : phoneUnactiveLink} onClick={() => setMobileMenuOpen(false)}>
                  {t("nav.second-link")}
                </NavLink>
                <NavLink to='/Recordings' className={({isActive}) => isActive ? phoneActiveLink : phoneUnactiveLink} onClick={() => setMobileMenuOpen(false)}>
                  {t("nav.third-link")}
                </NavLink>
                <NavLink to='/Photos' className={({isActive}) => isActive ? phoneActiveLink : phoneUnactiveLink} onClick={() => setMobileMenuOpen(false)}>
                  {t("nav.fourth-link")}
                </NavLink>
              </div>
              <div className="py-6 font-bordeaux-regular tracking-widest text-sm leading-6 text-col-lgh">
                <button onClick={() => {handleChangeLanguage("en"); setMobileMenuOpen(false)}} className="">
                	{/* Log in <span aria-hidden="true">&rarr;</span> */}
                    EN
                </button>
                /
                <button onClick={() => {handleChangeLanguage("de"); setMobileMenuOpen(false)}} className="">
                    {/* Log in <span aria-hidden="true">&rarr;</span> */}
                    DE
                </button>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}