import React from 'react'
import { useTranslation } from 'react-i18next'
import { Howl } from 'howler'
import audio from '../asset/audio/Richard_Strauss_Konzert.mp3'
import Blog from './Blog'
import {NavLink} from 'react-router-dom'

const Home = () => {
   const [t] = useTranslation("global") // Here we pass the translation file

  //  const handleChangeLanguage = (lang: string) => {
  //   i18n.changeLanguage(lang);
  //  }\
	
	var sound = new Howl({
		src:[audio],
		html5: true,
		preload:true,
		volume: 0.3,
	})
	
	function playSound(){
		if (sound.playing()){
			sound.pause()
		}else{
			sound.play()
		}
	}
		
  return (
	
    <div className='main-bg flex flex-col gap-1 pt-10 md:pt-0'>
      {/* <Nav/> */}
      <section className='flex flex-row justify-stretch text-col-lgh h-screen'>
        <div className='bg-home-image pos-pos bg-cover bg-center hidden lg:block w-full'></div>
        <div className='w-full block lg:hidden h-full px-8 md:px-14 gap-y-5 bg-home-image pos-pos bg-cover bg-center lg:bg-none absolute opacity-30'></div>
        <div className='w-full h-full flex flex-col justify-center items-center px-8 md:px-14 gap-y-5 z-10'>
            <div className='w-full flex flex-col justify-center items-end gap-y-5'>
              <div className='flex justify-center items-center gap-5'>
                <div className='play-btn relative w-fit h-fit'>
                  <svg className={`hover:fill-col-hg fill-col-lgh w-20 lg:w-12 cursor-pointer `} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" onClick={playSound}>
                  <path fill-rule="evenodd" d="M12 4.8a.6.6 0 0 1 .6.6v13.2a.6.6 0 1 1-1.2 0V5.4a.6.6 0 0 1 .6-.6ZM9.6 7.2a.6.6 0 0 1 .6.6v8.4a.6.6 0 1 1-1.2 0V7.8a.6.6 0 0 1 .6-.6Zm4.8 0a.6.6 0 0 1 .6.6v8.4a.6.6 0 1 1-1.2 0V7.8a.6.6 0 0 1 .6-.6ZM7.2 9a.6.6 0 0 1 .6.6v4.8a.6.6 0 1 1-1.2 0V9.6a.6.6 0 0 1 .6-.6Zm9.6 0a.6.6 0 0 1 .6.6v4.8a.6.6 0 1 1-1.2 0V9.6a.6.6 0 0 1 .6-.6Zm-12 1.2a.6.6 0 0 1 .6.6v2.4a.6.6 0 1 1-1.2 0v-2.4a.6.6 0 0 1 .6-.6Zm14.4 0a.6.6 0 0 1 .6.6v2.4a.6.6 0 1 1-1.2 0v-2.4a.6.6 0 0 1 .6-.6Z" clip-rule="evenodd"></path>
                  </svg>
                </div>
                
                <p className='max-w-[17rem] text-sm'>Richard Strauss, Konzert für Oboe und kleines Orchester D-Dur</p>
              </div>
              <span className='w-72 h-0.5 bg-col-hg'></span>
              <p className='text-2xl text-right leading-normal lg:pl-19 font-hind-kochi-light'>
                  {t("home.body")} {/* {translate ("pass the key")} */}
              </p>
            </div>
            <NavLink to='/Bio' className='px-6 py-3 border-2 border-col-lgh font-hind-kochi-bold text-lg hover:border-col-hg hover:text-col-hg'>{t("buttons.full-bio")}</NavLink>
        </div>
      </section>

      <section className='text-center text-col-lgh py-10 flex flex-col gap-10 justify-center items-center'>
        <div className='flex flex-col justify-center items-center gap-6 font-bordeaux-regular tracking-widest text-xl leading-6 text-col-lgh'>
          {/* <p className='tracking-norm'>VOJTĚCH</p> */}
          {/* <p className='text-4xl md:text-5xl tracking-norm'>{t("home.motto")}</p> */}
          <span className='w-[20rem] md:w-[30rem] h-0.5 bg-col-hg'></span>
          <p className='w-full lg:w-[50%] text-2xl text-center leading-normal font-hind-kochi-light px-5'>{t("home.motto")}</p>
        </div>
        <div className='flex justify-center items-center flex-wrap gap-5'>
          <div className='bg-wai-image-1 w-[20rem] h-[15rem] md:w-[30rem] md:h-[20rem] bg-cover pos-pos'></div>
          <div className='bg-wai-image-2 w-[20rem] h-[15rem] md:w-[30rem] md:h-[20rem] bg-cover pos-pos'></div>
          <div className='bg-wai-image-3 w-[20rem] h-[15rem] md:w-[30rem] md:h-[20rem] bg-cover pos-pos'></div>
        </div>
      </section>
	    <Blog/>
      {/* <Footer/> */}
    </div>
  )
}

export default Home