import React from 'react'
import { useTranslation } from 'react-i18next'
import {NavLink} from 'react-router-dom'

const Footer = () => {

    const [t] = useTranslation("global")

  return (
    <>
        <footer className='flex flex-col bg-black px-11 py-10 gap-5 leading-normal font-hind-kochi-light'>
            <div className='flex  justify-center  text-col-lgh  text-left px-5 gap-10 2xl:gap-40 flex-wrap'>
                <div className='flex flex-col gap-5 md:w-[30%]'>
                    <p className='font-hind-kochi-semibold'>{t("footer.contact-header")}</p>
                    <p>{t("footer.contact-content.email")}<br/>{t("footer.contact-content.address")}<br/>{t("footer.contact-content.phone")}</p>
                    <div className='flex gap-5'>                        
                    </div>
                </div>
                <div className='flex gap-10'>
                    <div className='flex flex-col gap-3'>
                        <p className='font-hind-kochi-semibold'>{t("footer.header1")}</p>
                        <a href='https://lso.de/kuenstler/oboen-vojtech-pospisil/' className='hover:text-col-hg'>{t("footer.link1")}</a>
                        <a href='#' className='hover:text-col-hg'>Link</a>
                        <a href='#' className='hover:text-col-hg'>Link</a>
                        <a href='#' className='hover:text-col-hg'>Link</a>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <p className='font-hind-kochi-semibold'>{t("footer.header2")}</p>
                        <NavLink to="/" className='hover:text-col-hg'>{t("nav.first-link")}</NavLink>
                        <NavLink to="/Bio" className='hover:text-col-hg'>{t("nav.second-link")}</NavLink>
                        <NavLink to="/Recordings" className='hover:text-col-hg'>{t("nav.third-link")}</NavLink>
                        <NavLink to="/Photos" className='hover:text-col-hg'>{t("nav.fourth-link")}</NavLink>
                    </div>
                </div>
                <div className='flex justify-center items-center gap-5 w-fit flex-wrap'>
                    <div className='bg-wai-image-1 w-[15rem] h-[9rem] bg-cover pos-pos'></div>
                    <div className='bg-wai-image-2 w-[15rem] h-[9rem] bg-cover pos-pos'></div>
                </div>
            </div>
            <span className='w-full h-0.5 bg-col-hg'></span>
            <div>
                <p className='text-col-lgh text-left font-bordeaux-regular tracking-norm text-lg'>VOJTĚCH POSPÍŠIL</p>
            </div>
        </footer>
    </>
  )
}

export default Footer